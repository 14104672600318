import React, { Component } from "react"
import { Link } from "gatsby"
import homeImage from "../images/home-icon-black.svg"
import backImage from "../images/arrow-white-left.svg"
import $ from "jquery"
import toolsPaper from "../images/tools-paper.svg"
import toolsEvent from "../images/tools-events.svg"
import toolsForms from "../images/tools-forms.svg"
import toolsContact from "../images/tools-contact.svg"
import AlertsSlider from "../components/alertsslider"
import LinkUrl from "../components/linkurl"


class TopHero extends Component {
		componentDidMount () {
			$('.departments-menu').click(function() {
				$(this).toggleClass('open');
			});
		}

		constructor() {
		  var today = new Date();
		  var month = (today.getMonth() + 1).toString();
		  if (month.length < 2) {
		    month = `0${month}`;
		  }
		  var day = today.getDate().toString();
		  if (day.length < 2) {
		    day = `0${day}`;
		  }

		  var date = today.getFullYear() +'-'+month+ '-'+day;
		  super();
		  this.state = {
		    currentDateTime: date,
		  }
		}
		render() {
			const landingPage= this.props.landing
			const groupMenu = this.props.menu
			const isLanding = this.props.islanding
			const allPages = this.props.allPages
			const contactPageArr = this.props.contactPage
			const showNews = this.props.showNews
			const showEvents = this.props.showEvents
			const showDocs = this.props.showDocs
			var parentMenu = []
			var alerts = this.props.alerts

			groupMenu.edges && groupMenu.edges.map((colItem,colkey) => (
				colItem.node.drupal_parent_menu_item === null ? (
					parentMenu[colkey] = [],
					parentMenu[colkey]['parent'] = colItem,
					parentMenu[colkey]['submenu'] = []
				) : (
					null
				)
			))

			parentMenu && parentMenu.map((parentItem,parentkey) => (
				groupMenu.edges && groupMenu.edges.map((menuItem,menukey) => (
					 menuItem.node.drupal_parent_menu_item && menuItem.node.drupal_parent_menu_item.indexOf(parentItem.parent.node.drupal_id) !== -1 ? (
						parentMenu[parentkey]['submenu'][menukey] = [],
						parentMenu[parentkey]['submenu'][menukey]['item'] = menuItem,
						parentMenu[parentkey]['submenu'][menukey]['subitems'] = []
					) : (
						null
					)
				))
			))


			parentMenu && parentMenu.map((parentItem,parentkey) => (
				parentItem.submenu && parentItem.submenu.map((submenuItem,subkey) => (
					groupMenu.edges && groupMenu.edges.map((menuItem,menukey) => (
						 menuItem.node.drupal_parent_menu_item && menuItem.node.drupal_parent_menu_item.indexOf(submenuItem.item.node.drupal_id) !== -1 ? (
							parentMenu[parentkey]['submenu'][subkey]['subitems'][menukey] = menuItem
						) : (
							null
						)
					))
				))
			))

			var tempGroup, temp
			var filteredAlerts

			var alertsTemp = alerts && alerts.edges.filter(
				item => {
							item.node.relationships.group_content__departments_group_node_alert !== null ||
							item.node.field_global_post ? (
								temp = true
							) : (
								temp = false
							)
							return temp
						}
				)

			filteredAlerts = []
			const groupId = landingPage.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id

			if(alertsTemp && alerts !== undefined) {
				filteredAlerts = alertsTemp.filter(item => {
					item.node.field_global_post ? (
						tempGroup = true
					) : (
						item.node.relationships.group_content__departments_group_node_alert && item.node.relationships.group_content__departments_group_node_alert.map((gr,key) => (
							key === 0 ? (tempGroup = false) : (null),
							gr.relationships.gid.drupal_internal__id === groupId ? (
								tempGroup = true
							) : (
								null
							)
						))
					)

					return tempGroup

				})
			}

			return (
				<section className={"top-hero position-"+ landingPage.relationships.field_hero.field_image_alignment} style={landingPage.relationships.field_hero.relationships !== null && landingPage.relationships.field_hero.relationships.field_media_image !== null && landingPage.relationships.field_hero.relationships.field_media_image.relationships.field_media_image !== null ?
				 {backgroundImage: `url(${landingPage.relationships.field_hero.relationships.field_media_image.relationships.field_media_image.uri.url})`} : null} >

					<AlertsSlider allAlerts={filteredAlerts} groupId={landingPage.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} globalPage={false} filteredAlerts={true} />
					<div className={filteredAlerts.length && filteredAlerts.length > 0 ? 'departments-headline' : 'departments-headline no-alerts'}>
						<div className="container">

							{isLanding && isLanding === "1" ? (
								null
							) : (
								<Link className="back" to={landingPage.path.alias}><img src={backImage} alt="go back" />Back</Link>
							)}
							<div className="uk-grid" data-uk-grid>
								<div className="uk-width-3-4@s uk-width-expand uk-first-column">
									{landingPage.relationships.field_hero.field_title_html ? (
										<h1 className="departments-title">{landingPage.relationships.field_hero.field_title_html.value}</h1>
									) : (
										null
									)}
								</div>
								<div className="uk-width-1-4@s uk-width-auto">
									{groupMenu.edges.length > 0 ? (
										<div className="hamburger-menu">
											<a className="departments-menu" data-uk-toggle="target: .agency-menu" data-uk-navbar-toggle href="#" title="agency menu" aria-label="agency menu">
												Menu
												<span className="icon-bar"></span>
												<span className="icon-bar"></span>
												<span className="icon-bar"></span>
											</a>
										</div>
									) : (
										null
									)}
								</div> {/*uk-4*/}
							</div> {/*uk-grid*/}
							<div className="agency-menu uk-animation-fade uk-navbar-dropdown uk-open uk-navbar-dropdown-bottom-left" data-uk-navbar hidden>
								<div className="container">
									{landingPage.relationships.field_parent_agency ? (
										<div className="intro-agency">
											<img src={homeImage} alt="home-black" />
											<div className="menu-name parent-agency">
												<Link to={landingPage.relationships.field_parent_agency.path.alias}>Agency Home</Link>
												<Link to={landingPage.path.alias} className="with-dash">{landingPage.relationships.field_hero.field_title_html.value}</Link>
											</div>

										</div>
									) : (
										<div className="intro-agency">
											<img src={homeImage} alt="home-black" />
											<div className="menu-name">
												<Link to={landingPage.path.alias}>Agency Home</Link>
											</div>
										</div>
									)}

									<header className="menu-header">
										<h2 className="h3">{landingPage.title}</h2>

										<ul className="tools">
											{showNews !== false && showNews !== undefined  ? (
												<li><img src={toolsPaper} alt="paper" /><Link to={landingPage.path.alias+'/news'}>News</Link></li>
											) : (
												null
											)}

											{showEvents !== false && showEvents !== undefined  ? (
												<li><img src={toolsEvent} alt="event" /><Link to={landingPage.path.alias+'/events'}>Events</Link></li>
											) : (
												null
											)}

											{showDocs !== false && showDocs !== undefined  ? (
												<li><img src={toolsForms} alt="forms" /><Link to={"/document-center?guid="+landingPage.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id}>Forms & Docs</Link></li>
											) : (
												null
											)}

											{contactPageArr !== undefined &&  contactPageArr.edges.length > 0 ? (
												<li><img src={toolsContact} alt="contact" /><Link to={contactPageArr.edges[0].node.path.alias}>Contact</Link></li>
											) : (
												null
											)}
										</ul>

									</header>
									{parentMenu && parentMenu.length > 0 ? (
										<div className={"uk-grid uk-grid-custom-large uk-grid-large uk-child-width-1-2@m uk-child-width-1-"+parentMenu.filter(Boolean).length+"@l uk-open"} data-uk-grid>
											{parentMenu && parentMenu.map((parentItem,pkey) => (
												<div key={"col"+pkey}>
													{parentItem.submenu && parentItem.submenu.map((menuItem,menukey) => (
														<ul key={"menu"+menukey} className="uk-nav uk-navbar-dropdown-nav">
															<li><h3 className="blue-underline">{menuItem.item.node.title}</h3></li>
															{menuItem.subitems && menuItem.subitems.map((subItem,subkey) => (
																subItem.node.link && subItem.node.link !== undefined ? (
																	<li key={"subitem"+ subkey}>
																		<LinkUrl link={subItem.node.link.uri} allLinks={allPages} title={subItem.node.title} description={null} />
																	</li>
																) : (
																	null
																)
															))}
														</ul>
													))}
												</div>
											))}
										</div>
									) : (
										null
									)}

								</div>
							</div>

						</div> {/*container*/}
					</div>
				</section>
			)
		}
}

export default TopHero
